@keyframes OverlayFocus {
    from {
        opacity: 0;
        background-color: rgba(0, 0, 0, 0);
        -webkit-backdrop-filter: blur(0);
        backdrop-filter: blur(0);
    }

    to {
        opacity: 1;
        background-color: rgba(0, 0, 0, 0.5);
        -webkit-backdrop-filter: blur(5rem);
        backdrop-filter: blur(5rem);
    }
}

.overlay {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20000;
    animation-name: OverlayFocus;
    animation-iteration-count: 1;
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
}

.overlay .overlay-title {
    padding: 1rem;
    box-sizing: border-box;
}

.overlay .close-button {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    color: #6eba77;
}
