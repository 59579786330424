#homepage-hero {
    background: url(../../assets/heros/cornfield.jpg);
}

#hero-left {
    padding: 2rem;
    border-radius: 1rem;
    background-color: rgba(0, 0, 0, 0.5);
}

#hero-right {
    padding: 2rem;
    text-align: center;
}

#homepage-hero h1 {
    font-size: 2em;
    font-weight: normal;
}

#homepage-hero h1 small {
    font-weight: lighter;
}

#homepage-hero p {
    font-size: 1.25rem;
    font-weight: lighter;
}
