.Container {
    --highlight: orange;
    padding: 0;
}

.Title {
    background-image: url(../assets/cornmaze/field.jpg);
    background-attachment: fixed;
    background-size: cover;
    box-sizing: border-box;
}

.Title .Cover {
    box-sizing: border-box;
    padding-top: 3rem;
    padding-bottom: 3rem;
    padding-left: 15vw;
    padding-right: 15vw;
    -webkit-backdrop-filter: blur(2rem);
    backdrop-filter: blur(2rem);
    height: calc(100vh - var(--navbar-height));
    display: flex;
    align-items: center;
}

.Title .Cover2 img {
    width: 100%;
}

.Details {
    box-sizing: border-box;
    padding: 2rem;
}

.TitleWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.TitleWrapper .Title {
    background: var(--highlight);
    color: var(--maroon);
    width: fit-content;
    text-align: center;
    padding: 1rem;
}

.EventDetailTitle {
    font-size: 4rem;
    margin-left: 1rem;
    color: var(--bs-success);
}

.EventDetailDescription {
    font-size: 2rem;
    color: black;
}

.CornMazeEvent {
    background-attachment: fixed;
    background-size: cover;
    min-height: 50vh;
}
