.ribbon-protector {
    position: relative;
    z-index: 1;
}

.ribbon {
    font-size: 16px !important;
    /* This ribbon is based on a 16px font side and a 24px vertical rhythm. I've used em's to position each element for scalability. If you want to use a different font size you may have to play with the position of the ribbon elements */
    width: 50%;
    position: relative;
    background: #6eba77;
    color: #fff;
    text-align: center;
    padding: 1em 2em; /* Adjust to suit */
    margin: 2em auto 3em; /* Based on 24px vertical rhythm. 48px bottom margin - normally 24 but the ribbon 'graphics' take up 24px themselves so we double it. */
}

.ribbon:before,
.ribbon:after {
    content: '';
    position: absolute;
    display: block;
    bottom: -1em;
    border: 1.5em solid #009e15;
    z-index: -1;
}

.ribbon:before {
    left: -2em;
    border-right-width: 1.5em;
    border-left-color: transparent;
}

.ribbon:after {
    right: -2em;
    border-left-width: 1.5em;
    border-right-color: transparent;
}

.ribbon .ribbon-content:before,
.ribbon .ribbon-content:after {
    content: '';
    position: absolute;
    display: block;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.2) transparent transparent transparent;
    bottom: -1em;
}

.ribbon .ribbon-content:before {
    left: 0;
    border-width: 1em 0 0 1em;
}

.ribbon .ribbon-content:after {
    right: 0;
    border-width: 1em 1em 0 0;
}

h3.ribbon {
    font-size: 1rem !important;
}
