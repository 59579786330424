.hero {
    color: white;
    font-family: 'Roboto Slab', serif;
    padding: 0;
    min-height: calc(100vh - var(--navbar-height));
    background-attachment: fixed;
}

.hero .hero-title {
    background: rgba(255, 255, 255, 0.5);
    color: black;
    padding: 1em;
    border-radius: 0.5em;
    box-shadow: 0 0 1em 0 black;
    -webkit-backdrop-filter: blur(1em);
    backdrop-filter: blur(1em);
}

.hero .hero-scroll-hint {
    color: black;
    -webkit-backdrop-filter: blur(0.5em);
    backdrop-filter: blur(0.5em);
}
