nav.navbar {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10000;
    background: rgba(255, 255, 255, 0.5) !important;
    -webkit-backdrop-filter: blur(2rem);
    backdrop-filter: blur(5rem);
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.5);
}

.navbar .shopping-cart-button {
    font-size: 1.5em;
}
