.WhatsIncluded {
    --green-hue: 135;
    --green-saturation: 100%;

    --green1: hsl(var(--green-hue) var(--green-saturation) 27%);
    --green2: hsl(var(--green-hue) var(--green-saturation) 32%);
    --green3: hsl(var(--green-hue) var(--green-saturation) 37%);
    --green4: hsl(var(--green-hue) var(--green-saturation) 42%);

    --green-foreground: hsl(var(--green-hue) var(--green-saturation) 80%);
}

.Greeting {
    font-size: 1.25rem;
    text-align: center;
}

.Icon {
    --maxsize: 10rem;
    background: hsla(var(--green-hue), var(--green-saturation), 100%, 10%);
    max-width: var(--maxsize);
    max-height: var(--maxsize);
    transition: 0.25s background;
}

.Icon:hover {
    background: hsla(var(--green-hue), var(--green-saturation), 100%, 100%);
    box-shadow: 0 0 10rem 0 black;
}

.Green1 {
    background: var(--green1);
    color: var(--green-foreground);
}

.Green2 {
    background: var(--green2);
    color: var(--green-foreground);
}

.Green3 {
    background: var(--green3);
    color: var(--green-foreground);
}

.Green4 {
    background: linear-gradient(to bottom right, var(--green3), var(--green4), green);
    color: var(--green-foreground);
}
