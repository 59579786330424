.merch-page .image-selector .image-selector-item {
    box-sizing: border-box;
    margin: 0.5em;
}

.merch-page .image-selector .image-selector-item:first-child {
    margin-left: 0;
}

.merch-page .image-selector .image-selector-item:last-child {
    margin-right: 0;
}

.merch-page .product-config-title {
    font-size: 1.2em;
}
